import crudGetters from "@/store/templates/crud/getters";

export default {
  localization: state => state.model,
  id: state => state.model.id,
  nl_be: state => state.model.nl_be,
  nl_nl: state => state.model.nl_nl,
  fr_fr: state => state.model.fr_fr,
  fr_be: state => state.model.fr_be,
  en_gb: state => state.model.en_gb,
  de_de: state => state.model.de_de,
  type: state => state.model.type,
  ...crudGetters
};
