<template>
  <v-card color="white">
    <crud-header
      :title="nl_be ? nl_be : fr_be"
      type="Localization"
      store="localization"
    />
    <localization />
  </v-card>
</template>

<script type="text/babel">
import localizationModule from "@/store/modules/localization";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import Localization from "../../components/localization/Localization";

const { mapGetters } = createNamespacedHelpers("localization");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "localization",
  localizationModule
);

export default {
  name: "localization-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Localization,
    CrudHeader
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["nl_be", "fr_be"])
  },
  ...detailViewLifecycleMethods
};
</script>
