<template>
  <table-overview
    title="Localizations"
    store="localizations"
    forceInitialSort
    :headers="[
      { text: 'nl_be', value: 'nl_be' },
      { text: 'nl_nl', value: 'nl_nl', sortable: false },
      { text: 'fr_be', value: 'fr_be', sortable: false },
      { text: 'fr_fr', value: 'fr_fr', sortable: false },
      { text: 'en_gb', value: 'en_gb', sortable: false },
      { text: 'de_de', value: 'de_de', sortable: false },
      { text: 'type', value: 'type' },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'localizations_view'
      }
    ]"
  >
    <localization-table-row
      slot="table-row"
      slot-scope="{ item }"
      :localization-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "@buldit/fe-common/src/components/overview/TableOverview";
import localizationsModule from "@/store/modules/localizations";
import { mapOverviewLifecycleMethods } from "../helpers";
import LocalizationTableRow from "../../components/localization/LocalizationTableRow";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "localizations",
  localizationsModule
);

export default {
  name: "localizations-view",
  components: {
    LocalizationTableRow,
    TableOverview
  },
  data: () => ({}),
  ...overviewLifecycleMethods
};
</script>
