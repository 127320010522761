<template>
  <v-container fluid>
    <v-text-field
      v-model="nl_be"
      v-validate="'max:255'"
      label="nl_be"
      autocomplete="not-nl-be"
      :counter="255"
      :error-messages="errors.collect('nl_be')"
      data-vv-name="nl_be"
      required
    />
    <v-text-field
      v-model="nl_nl"
      v-validate="'max:255'"
      label="nl_nl"
      autocomplete="not-nl-nl"
      :counter="255"
      :error-messages="errors.collect('nl_nl')"
      data-vv-name="nl_nl"
      required
    />
    <v-text-field
      v-model="fr_be"
      v-validate="'max:255'"
      label="fr_be"
      autocomplete="not-fr-be"
      :counter="255"
      :error-messages="errors.collect('fr_be')"
      data-vv-name="fr_be"
      required
    />
    <v-text-field
      v-model="fr_fr"
      v-validate="'max:255'"
      label="fr_fr"
      autocomplete="not-fr-fr"
      :counter="255"
      :error-messages="errors.collect('fr_fr')"
      data-vv-name="fr_fr"
      required
    />
    <v-text-field
      v-model="en_gb"
      v-validate="'max:255'"
      label="en_gb"
      autocomplete="not-en-gb"
      :counter="255"
      :error-messages="errors.collect('en_gb')"
      data-vv-name="en_gb"
      required
    />
    <v-text-field
      v-model="de_de"
      v-validate="'max:255'"
      label="de_de"
      autocomplete="not-de-de"
      :counter="255"
      :error-messages="errors.collect('de_de')"
      data-vv-name="de_de"
      required
    />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
const { mapComputed } = createNamespacedHelpers("localization");

export default {
  name: "localization",
  components: {},
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    ...mapComputed(["nl_be", "nl_nl", "fr_fr", "fr_be", "en_gb", "de_de"])
  }
};
</script>
