var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Localizations","store":"localizations","forceInitialSort":"","headers":[
    { text: 'nl_be', value: 'nl_be' },
    { text: 'nl_nl', value: 'nl_nl', sortable: false },
    { text: 'fr_be', value: 'fr_be', sortable: false },
    { text: 'fr_fr', value: 'fr_fr', sortable: false },
    { text: 'en_gb', value: 'en_gb', sortable: false },
    { text: 'de_de', value: 'de_de', sortable: false },
    { text: 'type', value: 'type' },
    {
      text: 'Actions',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'localizations_view'
    }
  ]},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('localization-table-row',{attrs:{"localization-id":item}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }