import crudMutations from "@/store/templates/crud/mutations";

export default {
  setNl_be(state, nl_be) {
    state.model.nl_be = nl_be;
  },
  setNl_nl(state, nl_nl) {
    state.model.nl_nl = nl_nl;
  },
  setFr_fr(state, fr_be) {
    state.model.fr_be = fr_be;
  },
  setFr_be(state, fr_fr) {
    state.model.fr_fr = fr_fr;
  },
  setEn_gb(state, en_gb) {
    state.model.en_gb = en_gb;
  },
  setDe_de(state, de_de) {
    state.model.de_de = de_de;
  },
  setType(state, type) {
    state.model.type = type;
  },
  ...crudMutations
};
