<template>
  <tr>
    <td>{{ localization.nl_be }}</td>
    <td>{{ localization.nl_nl }}</td>
    <td>{{ localization.fr_be }}</td>
    <td>{{ localization.fr_fr }}</td>
    <td>{{ localization.en_gb }}</td>
    <td>{{ localization.de_de }}</td>
    <td>{{ type }}</td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'localizations.show',
          params: { localizationId: localization.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { isEmpty } from "lodash";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapLocalizationGetters } = createNamespacedHelpers(
  "localizations"
);

export default {
  name: "localization-table-row",
  props: {
    localizationId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapLocalizationGetters([FIND_BY_ID]),
    localization() {
      return this[FIND_BY_ID](this.localizationId);
    },
    type() {
      if (!isEmpty(this.localization.collectionSubject)) {
        return "SUBJECT";
      } else if (
        !isEmpty(this.localization.questions) ||
        !isEmpty(this.localization.questionHoverForm) ||
        !isEmpty(this.localization.option) ||
        !isEmpty(this.localization.optionHover) ||
        !isEmpty(this.localization.optionRemark)
      ) {
        return "FORM";
      } else if (
        !isEmpty(this.localization.label) ||
        !isEmpty(this.localization.placeholder) ||
        !isEmpty(this.localization.infoHover) ||
        !isEmpty(this.localization.questionLabel) ||
        !isEmpty(this.localization.questionRemark) ||
        !isEmpty(this.localization.questionHover)
      ) {
        return "QUESTION";
      }
      return "";
    }
  }
};
</script>
